import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth.slice";
import contactsSlice from "./slices/contacts.slice";
import propertiesSlice from "./slices/properties.slice";
import offerBannerSlice from "./slices/offerBanner.slice";

const store = configureStore({
	reducer: {
		contacts: contactsSlice.reducer,
		auth: authSlice.reducer,
		properties: propertiesSlice.reducer,
		offerBanner: offerBannerSlice.reducer,
	},
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;

export default store;
