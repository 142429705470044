import { createSlice } from "@reduxjs/toolkit";
import {
	replaceCurrentUserReducer,
	replaceEmailReducer,
	setAuthLoginLoadingReducer,
	setAuthSignupLoadingReducer,
	setAuthVerifyLoadingReducer,
	setGetCurrentUserLoadingReducer,
	setIsResetPasswordModalVisibleReducer,
	setLogoutLoadingReducer,
	setResendVerificationEmailLoadingReducer,
	setSignupEmailReducer,
} from "../reducers/auth.reducer";

export interface IUser {
	_id: string;
	uid: string;
	name: string;
	email: string;
	phone: string;
	exp: number;
	iat: number;
	listings: [];
	properties: [];
	createdAt: string;
	updatedAt: string;
}

export interface IAuthState {
	email: string;
	isResetPasswordModalVisible: boolean;
	signupLoading: boolean;
	signupEmail: string;
	loginLoading: boolean;
	verifyLoading: boolean;
	getCurrentUserLoading: boolean;
	currentUser: IUser | null;
	logoutLoading: boolean;
	resendVerificationEmailLoading: boolean;
}

const initialState: IAuthState = {
	email: "",
	isResetPasswordModalVisible: false,
	signupLoading: false,
	signupEmail: "",
	loginLoading: false,
	verifyLoading: false,
	getCurrentUserLoading: false,
	currentUser: null,
	logoutLoading: false,
	resendVerificationEmailLoading: false,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		replaceEmail: replaceEmailReducer,
		setIsResetPasswordModalVisible: setIsResetPasswordModalVisibleReducer,
		setAuthSignupLoading: setAuthSignupLoadingReducer,
		setAuthLoginLoading: setAuthLoginLoadingReducer,
		setAuthVerifyLoading: setAuthVerifyLoadingReducer,
		setGetCurrentUserLoading: setGetCurrentUserLoadingReducer,
		replaceCurrentUser: replaceCurrentUserReducer,
		setLogoutLoading: setLogoutLoadingReducer,
		setSignupEmail: setSignupEmailReducer,
		setResendVerificationEmailLoading: setResendVerificationEmailLoadingReducer,
	},
});

export const authActions = authSlice.actions;
export default authSlice;
