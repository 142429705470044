import { FC, lazy, Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import "./app.scss";

import { TRootState } from "./store";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { getCurrentUserHandler } from "./actions/auth.action";
import FullPageLoader from "./components/fullPageLoader";
import { useAppSelector } from "./hooks/useAppSelector";

const Account = lazy(() => import("./pages/account"));
const NotFound = lazy(() => import("./pages/notFound"));
const Signup = lazy(() => import("./pages/signup"));
const Login = lazy(() => import("./pages/login"));

const SignupSuccess = lazy(() => import("./pages/signup/success"));
const VerifyAccount = lazy(() => import("./pages/verify"));

const CreateProperty = lazy(() => import("./pages/account/createProperty"));
const UpdateProperty = lazy(() => import("./pages/account/updateProperty"));

const Navbar = lazy(() => import("./components/navbar"));
const Footer = lazy(() => import("./components/footer"));

const Properties = lazy(() => import("./pages/properties"));
const Home = lazy(() => import("./pages/home"));
const Contact = lazy(() => import("./pages/contact"));

const ViewProperty = lazy(() => import("./pages/properties/viewProperty"));
const App: FC = () => {
	const dispatch = useAppDispatch();

	const { getCurrentUserLoading } = useAppSelector((state: TRootState) => state.auth);

	useEffect(() => {
		dispatch(getCurrentUserHandler());
	}, []);

	return (
		<HelmetProvider>
			<Suspense fallback={<FullPageLoader />}>
				{/* <CssBaseline /> */}
				{!getCurrentUserLoading ? (
					<Router>
						<div className="min-h-screen flex flex-col">
							<div className="flex-1">
								<Navbar />

								<Routes>
									<Route path="/" element={<Home />} />
									<Route path="/contact" element={<Contact />} />
									<Route path="/properties" element={<Properties />} />
									<Route path="/properties/:uid" element={<ViewProperty />} />

									<Route path="/signup" element={<Signup />} />
									<Route path="/signup/success" element={<SignupSuccess />} />
									<Route path="/login" element={<Login />} />
									<Route path="/verify" element={<VerifyAccount />} />
									<Route path="/account" element={<Account />} />
									<Route
										path="/account/properties/create"
										element={<CreateProperty />}
									/>
									<Route
										path="/account/properties/:uid"
										element={<UpdateProperty />}
									/>

									<Route path="/404" element={<NotFound />} />
									<Route path="*" element={<Navigate replace to="/404" />} />
								</Routes>
							</div>

							<Footer />
						</div>
					</Router>
				) : (
					<FullPageLoader />
				)}
			</Suspense>
		</HelmetProvider>
	);
};

export default App;
