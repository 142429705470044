import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { App as AntApp, ConfigProvider } from "antd";
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ConfigProvider theme={{ token: { colorPrimary: "#9e7160", colorLink: "#9e7160" } }}>
				<AntApp>
					<App />
				</AntApp>
			</ConfigProvider>
		</Provider>
	</React.StrictMode>,
);
