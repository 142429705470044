import { PayloadAction } from "@reduxjs/toolkit";
import { IFacility, IField, IPropertiesState, IProperty } from "../slices/properties.slice";

export const replacePropertiesReducer = (
	state: IPropertiesState,
	action: PayloadAction<IProperty[]>,
) => {
	state.records = action.payload;
};

export const replaceFeaturedPropertiesReducer = (
	state: IPropertiesState,
	action: PayloadAction<IProperty[]>,
) => {
	state.featuredProperties = action.payload;
};

export const setGetPropertiesLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getPropertiesLoading = action.payload;
};

export const setGetSingleLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getSingleLoading = action.payload;
};

export const updateNewPropertyReducer = (
	state: IPropertiesState,
	action: PayloadAction<Record<string, any>>,
) => {
	state.newProperty = { ...state.newProperty, ...action.payload };
};

export const setCreatePropertiesLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.createLoading = action.payload;
};

export const replaceCategoriesReducer = (
	state: IPropertiesState,
	action: PayloadAction<IField[]>,
) => {
	state.categories = action.payload;
};

export const replacePossessionsReducer = (
	state: IPropertiesState,
	action: PayloadAction<IField[]>,
) => {
	state.possessions = action.payload;
};

export const replacePropertyTypesReducer = (
	state: IPropertiesState,
	action: PayloadAction<IField[]>,
) => {
	state.propertyTypes = action.payload;
};

export const replaceConstructionStatusReducer = (
	state: IPropertiesState,
	action: PayloadAction<IField[]>,
) => {
	state.constructionStatus = action.payload;
};

export const replaceFurnishingStatusReducer = (
	state: IPropertiesState,
	action: PayloadAction<IField[]>,
) => {
	state.furnishingStatus = action.payload;
};

export const replaceUnitsReducer = (state: IPropertiesState, action: PayloadAction<IField[]>) => {
	state.units = action.payload;
};

export const replaceFacilitiesReducer = (
	state: IPropertiesState,
	action: PayloadAction<IFacility[]>,
) => {
	state.facilities = action.payload;
};

export const setGetCategoriesLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getCategoriesLoading = action.payload;
};

export const setGetPossessionLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getPossessionLoading = action.payload;
};

export const setGetPropertyTypesLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getPropertyTypesLoading = action.payload;
};

export const setGetConstructionStatusLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getConstructionStatusLoading = action.payload;
};

export const setGetFurnishingStatusLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getFurnishingStatusLoading = action.payload;
};

export const setGetUnitsLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getUnitsLoading = action.payload;
};

export const setGetFacilitiesLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.getFacilitiesLoading = action.payload;
};

export const replaceCurrentPropertyReducer = (
	state: IPropertiesState,
	action: PayloadAction<IProperty | null>,
) => {
	state.currentProperty = action.payload;
};

export const updatePropertyDataReducer = (
	state: IPropertiesState,
	action: PayloadAction<Record<string, any>>,
) => {
	state.updatePropertyData = { ...state.updatePropertyData, ...action.payload };
};

export const setPropertiesDeleteAttachmentLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.deleteAttachmentLoading = action.payload;
};

export const setPropertiesUpdateLoadingReducer = (
	state: IPropertiesState,
	action: PayloadAction<boolean>,
) => {
	state.updateLoading = action.payload;
};
