import { createSlice } from "@reduxjs/toolkit";

interface IOfferBannerState {
	getOfferBannerLoading: boolean;
	offerBanner: IOfferBanner | null;
}

const initialState: IOfferBannerState = {
	getOfferBannerLoading: true,
	offerBanner: null,
};

const offerBannerSlice = createSlice({
	initialState,
	name: "offerBanner",
	reducers: {
		setOfferBannerLoading: (state, action) => {
			state.getOfferBannerLoading = action.payload;
		},
		setOfferBanner: (state, action) => {
			state.offerBanner = action.payload;
		},
	},
});

export interface IOfferBanner {
	key: string;
	url: string;
}

export const offerBannerActions = offerBannerSlice.actions;
export default offerBannerSlice;
