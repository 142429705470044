import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "./auth.slice";
import {
	replaceFeaturedPropertiesReducer,
	replacePropertiesReducer,
	setGetPropertiesLoadingReducer,
	setGetSingleLoadingReducer,
	setCreatePropertiesLoadingReducer,
	updateNewPropertyReducer,
	replaceCategoriesReducer,
	replacePossessionsReducer,
	replacePropertyTypesReducer,
	replaceConstructionStatusReducer,
	replaceFurnishingStatusReducer,
	replaceUnitsReducer,
	replaceFacilitiesReducer,
	setGetCategoriesLoadingReducer,
	setGetPossessionLoadingReducer,
	setGetPropertyTypesLoadingReducer,
	setGetConstructionStatusLoadingReducer,
	setGetFurnishingStatusLoadingReducer,
	setGetUnitsLoadingReducer,
	setGetFacilitiesLoadingReducer,
	replaceCurrentPropertyReducer,
	updatePropertyDataReducer,
	setPropertiesDeleteAttachmentLoadingReducer,
	setPropertiesUpdateLoadingReducer,
} from "../reducers/properties.reducer";

export interface IAttachment {
	url: string;
	key: string;
}

export interface IFacility {
	_id: string;
	title: string;
	icon: string;
	key: string;
	createdAt: string;
	updatedAt: string;
}

export interface IField {
	_id: string;
	title: string;
	createdAt: string;
	updatedAt: string;
}

export interface IProperty {
	_id: string;
	uid: number;
	commission: string;
	featured: boolean;
	approved: boolean;
	images: IAttachment[];
	videos: IAttachment[];
	documents: IAttachment[];
	facilities: IFacility[];
	createdAt: string;
	updatedAt: string;
	basic_details: {
		title: string;
		description: string;
		price: number;
		discounted_price?: number;
		size?: number;
		unit: IField;
		property_type: IField;
		category: IField;
		security_deposit?: number;
		monthly_maintenance?: number;
		construction_status?: IField;
		furnishing_status: IField;
		possession: IField;
		property_age: string;
		location: {
			address: string;
			locality: string;
			city: string;
			state: string;
			country: string;
			pin_code: number;
			google_map_link?: string;
		};
		owner: {
			name: string;
			contact: string;
			user: IUser;
		};
	};
	specifications: {
		direction:
			| "North"
			| "South"
			| "East"
			| "West"
			| "North-East"
			| "North-West"
			| "South-East"
			| "South-West";
		floor?: string;
		total_floors?: string;
		bed_rooms?: number;
		bath_rooms?: number;
		kitchens?: number;
		living_rooms?: number;
		dinning_rooms?: number;
		store_rooms?: number;
		pooja_rooms?: number;
		lobbies?: number;
		other_rooms?: number;
		balconies?: number;
		open_parking?: number;
		covered_parking?: number;
	};
	furnishing_details: {
		acs?: number;
		stoves?: number;
		wardrobes?: number;
		sofas?: number;
		dinning_tables?: number;
		beds?: number;
		geysers?: number;
		modular_kitchens?: number;
		fans: number;
		lights: number;
		refrigerators?: number;
		micro_ovens?: number;
		tvs?: number;
		dressing_tables?: number;
		tv_wall_panels?: number;
		washing_machines?: number;
		curtains?: number;
		water_purifiers?: number;
		exhaust?: number;
		dish_washers?: number;
		chimneys?: number;
	};
}

export interface IPropertiesState {
	records: IProperty[];
	featuredProperties: IProperty[];
	getPropertiesLoading: boolean;
	getSingleLoading: boolean;
	newProperty: Record<string, any>;
	createLoading: boolean;
	categories: IField[];
	possessions: IField[];
	propertyTypes: IField[];
	constructionStatus: IField[];
	furnishingStatus: IField[];
	units: IField[];
	facilities: IFacility[];
	getCategoriesLoading: boolean;
	getPossessionLoading: boolean;
	getPropertyTypesLoading: boolean;
	getConstructionStatusLoading: boolean;
	getFurnishingStatusLoading: boolean;
	getUnitsLoading: boolean;
	getFacilitiesLoading: boolean;
	currentProperty: IProperty | null;
	updatePropertyData: Record<string, any>;
	deleteAttachmentLoading: boolean;
	updateLoading: boolean;
}

const initialState: IPropertiesState = {
	records: [],
	featuredProperties: [],
	getPropertiesLoading: false,
	getSingleLoading: false,
	createLoading: false,
	newProperty: {} as IProperty,
	categories: [],
	furnishingStatus: [],
	possessions: [],
	propertyTypes: [],
	constructionStatus: [],
	units: [],
	facilities: [],
	getCategoriesLoading: false,
	getPossessionLoading: false,
	getPropertyTypesLoading: false,
	getConstructionStatusLoading: false,
	getFurnishingStatusLoading: false,
	getUnitsLoading: false,
	getFacilitiesLoading: false,
	currentProperty: null,
	updatePropertyData: {},
	deleteAttachmentLoading: false,
	updateLoading: false,
};

const propertiesSlice = createSlice({
	name: "properties",
	initialState,
	reducers: {
		replaceProperties: replacePropertiesReducer,
		replaceFeaturedProperties: replaceFeaturedPropertiesReducer,
		setGetPropertiesLoading: setGetPropertiesLoadingReducer,
		setGetSingleLoading: setGetSingleLoadingReducer,
		updateNewProperty: updateNewPropertyReducer,
		setCreatePropertiesLoading: setCreatePropertiesLoadingReducer,
		replaceCategories: replaceCategoriesReducer,
		replacePossessions: replacePossessionsReducer,
		replacePropertyTypes: replacePropertyTypesReducer,
		replaceConstructionStatus: replaceConstructionStatusReducer,
		replaceFurnishingStatus: replaceFurnishingStatusReducer,
		replaceUnits: replaceUnitsReducer,
		replaceFacilities: replaceFacilitiesReducer,
		setGetCategoriesLoading: setGetCategoriesLoadingReducer,
		setGetPossessionLoading: setGetPossessionLoadingReducer,
		setGetPropertyTypesLoading: setGetPropertyTypesLoadingReducer,
		setGetConstructionStatusLoading: setGetConstructionStatusLoadingReducer,
		setGetFurnishingStatusLoading: setGetFurnishingStatusLoadingReducer,
		setGetUnitsLoading: setGetUnitsLoadingReducer,
		setGetFacilitiesLoading: setGetFacilitiesLoadingReducer,
		replaceCurrentProperty: replaceCurrentPropertyReducer,
		updatePropertyData: updatePropertyDataReducer,
		setPropertiesDeleteAttachmentLoading: setPropertiesDeleteAttachmentLoadingReducer,
		setPropertiesUpdateLoading: setPropertiesUpdateLoadingReducer,
	},
});

export const propertiesActions = propertiesSlice.actions;
export default propertiesSlice;
